import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import logo from '../assets/project44_logo_lg.jpeg'

import 'primeicons/primeicons.css' //icons
import 'primereact/resources/primereact.min.css' //core css
import 'primereact/resources/themes/lara-light-indigo/theme.css' //theme

import {
  Card,
  CardBody,
  Menu,
  MenuGroup,
  MenuItem,
} from '@project44-manifest/react'
import { ChevronRightDouble, Cube, List } from '@project44-manifest/react-icons'
import CardHeader from 'react-bootstrap/esm/CardHeader'

export const Layout = () => {
  const navigate = useNavigate()
  const location = useLocation()

  function home() {
    navigate('/eubeginpage')
  }

  function tenants() {
    navigate('/eucreatetenants')
  }

  function rules() {
    navigate('/eucreaterules')
  }

  function tenantsAndRules() {
    navigate('/eutenantsandrules')
  }

  function users() {
    navigate('/euusers')
  }

  function downloaduserresultsfile() {
    navigate('/eudownloaduserresultsfile')
  }

  if (location.pathname === '/eubeginpage') {
    return (
      <>
        <Card style={{ marginBottom: '1rem' }}>
          <img
            style={{ height: '40px', marginLeft: '.5rem' }}
            src={logo}
            alt=""
          ></img>
          <CardHeader
            style={{
              marginLeft: '.5rem',
              fontWeight: 'bold',
              fontSize: 'xx-large',
            }}
          >
            Collaborative Visibility Admin Tools
          </CardHeader>
          <CardBody style={{ marginLeft: '.5rem' }}>
            <Menu>
              <MenuItem
                label="Home"
                startIcon={<Cube />}
                onClick={home}
              />
              <MenuGroup
                label="Admin Tools"
                startIcon={<List />}
              >
                <MenuItem
                  label="Tenant Creation"
                  startIcon={<ChevronRightDouble />}
                  onClick={tenants}
                />
                <MenuItem
                  label="CV Rules Creation"
                  startIcon={<ChevronRightDouble />}
                  onClick={rules}
                />
                <MenuItem
                  label="CV Tenant and Rules Creation"
                  startIcon={<ChevronRightDouble />}
                  onClick={tenantsAndRules}
                />
                <MenuItem
                  label="User Creation"
                  startIcon={<ChevronRightDouble />}
                  onClick={users}
                />
                <MenuItem
                  label="File Download"
                  startIcon={<ChevronRightDouble />}
                  onClick={downloaduserresultsfile}
                />
              </MenuGroup>
            </Menu>
          </CardBody>
        </Card>
      </>
    )
  } else if (location.pathname === '/eucreatetenants') {
    return (
      <>
        <Card style={{ marginBottom: '1rem' }}>
          <img
            style={{ height: '40px', marginLeft: '.5rem' }}
            src={logo}
            alt=""
          ></img>
          <CardHeader
            style={{
              marginLeft: '.5rem',
              fontWeight: 'bold',
              fontSize: 'xx-large',
            }}
          >
            Tenant Creation Tool
          </CardHeader>
          <CardBody style={{ marginLeft: '.5rem' }}>
            <Menu>
              <MenuItem
                label="Home"
                startIcon={<Cube />}
                onClick={home}
              />
              <MenuGroup
                label="Admin Tools"
                startIcon={<List />}
              >
                <MenuItem
                  label="Tenant Creation"
                  startIcon={<ChevronRightDouble />}
                  onClick={tenants}
                />
                <MenuItem
                  label="CV Rules Creation"
                  startIcon={<ChevronRightDouble />}
                  onClick={rules}
                />
                <MenuItem
                  label="CV Tenant and Rules Creation"
                  startIcon={<ChevronRightDouble />}
                  onClick={tenantsAndRules}
                />
                <MenuItem
                  label="User Creation"
                  startIcon={<ChevronRightDouble />}
                  onClick={users}
                />
                <MenuItem
                  label="File Download"
                  startIcon={<ChevronRightDouble />}
                  onClick={downloaduserresultsfile}
                />
              </MenuGroup>
            </Menu>
          </CardBody>
        </Card>
      </>
    )
  } else if (location.pathname === '/eucreaterules') {
    return (
      <>
        <Card style={{ marginBottom: '1rem' }}>
          <img
            style={{ height: '40px', marginLeft: '.5rem' }}
            src={logo}
            alt=""
          ></img>
          <CardHeader
            style={{
              marginLeft: '.5rem',
              fontWeight: 'bold',
              fontSize: 'xx-large',
            }}
          >
            CV Rules Creation Tool
          </CardHeader>
          <CardBody style={{ marginLeft: '.5rem' }}>
            <Menu>
              <MenuItem
                label="Home"
                startIcon={<Cube />}
                onClick={home}
              />
              <MenuGroup
                label="Admin Tools"
                startIcon={<List />}
              >
                <MenuItem
                  label="Tenant Creation"
                  startIcon={<ChevronRightDouble />}
                  onClick={tenants}
                />
                <MenuItem
                  label="CV Rules Creation"
                  startIcon={<ChevronRightDouble />}
                  onClick={rules}
                />
                <MenuItem
                  label="CV Tenant and Rules Creation"
                  startIcon={<ChevronRightDouble />}
                  onClick={tenantsAndRules}
                />
                <MenuItem
                  label="User Creation"
                  startIcon={<ChevronRightDouble />}
                  onClick={users}
                />
                <MenuItem
                  label="File Download"
                  startIcon={<ChevronRightDouble />}
                  onClick={downloaduserresultsfile}
                />
              </MenuGroup>
            </Menu>
          </CardBody>
        </Card>
      </>
    )
  } else if (location.pathname === '/euusers') {
    return (
      <>
        <Card style={{ marginBottom: '1rem' }}>
          <img
            style={{ height: '40px', marginLeft: '.5rem' }}
            src={logo}
            alt=""
          ></img>
          <CardHeader
            style={{
              marginLeft: '.5rem',
              fontWeight: 'bold',
              fontSize: 'xx-large',
            }}
          >
            User Creation
          </CardHeader>
          <CardBody style={{ marginLeft: '.5rem' }}>
            <Menu>
              <MenuItem
                label="Home"
                startIcon={<Cube />}
                onClick={home}
              />
              <MenuGroup
                label="Admin Tools"
                startIcon={<List />}
              >
                <MenuItem
                  label="Tenant Creation"
                  startIcon={<ChevronRightDouble />}
                  onClick={tenants}
                />
                <MenuItem
                  label="CV Rules Creation"
                  startIcon={<ChevronRightDouble />}
                  onClick={rules}
                />
                <MenuItem
                  label="CV Tenant and Rules Creation"
                  startIcon={<ChevronRightDouble />}
                  onClick={tenantsAndRules}
                />
                <MenuItem
                  label="User Creation"
                  startIcon={<ChevronRightDouble />}
                  onClick={users}
                />
                <MenuItem
                  label="File Download"
                  startIcon={<ChevronRightDouble />}
                  onClick={downloaduserresultsfile}
                />
              </MenuGroup>
            </Menu>
          </CardBody>
        </Card>
      </>
    )
  } else if (location.pathname === '/eudownloaduserresultsfile') {
    return (
      <>
        <Card style={{ marginBottom: '1rem' }}>
          <img
            style={{ height: '40px', marginLeft: '.5rem' }}
            src={logo}
            alt=""
          ></img>
          <CardHeader
            style={{
              marginLeft: '.5rem',
              fontWeight: 'bold',
              fontSize: 'xx-large',
            }}
          >
            Download Files
          </CardHeader>
          <CardBody style={{ marginLeft: '.5rem' }}>
            <Menu>
              <MenuItem
                label="Home"
                startIcon={<Cube />}
                onClick={home}
              />
              <MenuGroup
                label="Admin Tools"
                startIcon={<List />}
              >
                <MenuItem
                  label="Tenant Creation"
                  startIcon={<ChevronRightDouble />}
                  onClick={tenants}
                />
                <MenuItem
                  label="CV Rules Creation"
                  startIcon={<ChevronRightDouble />}
                  onClick={rules}
                />
                <MenuItem
                  label="CV Tenant and Rules Creation"
                  startIcon={<ChevronRightDouble />}
                  onClick={tenantsAndRules}
                />
                <MenuItem
                  label="User Creation"
                  startIcon={<ChevronRightDouble />}
                  onClick={users}
                />
                <MenuItem
                  label="File Download"
                  startIcon={<ChevronRightDouble />}
                  onClick={downloaduserresultsfile}
                />
              </MenuGroup>
            </Menu>
          </CardBody>
        </Card>
      </>
    )
  } else {
    return (
      <>
        <Card style={{ marginBottom: '1rem' }}>
          <img
            style={{ height: '40px', marginLeft: '.5rem' }}
            src={logo}
            alt=""
          ></img>
          <CardHeader
            style={{
              marginLeft: '.5rem',
              fontWeight: 'bold',
              fontSize: 'xx-large',
            }}
          >
            CV Tenants and Rules Creation Tool
          </CardHeader>
          <CardBody style={{ marginLeft: '.5rem' }}>
            <Menu>
              <MenuItem
                label="Home"
                startIcon={<Cube />}
                onClick={home}
              />
              <MenuGroup
                label="Admin Tools"
                startIcon={<List />}
              >
                <MenuItem
                  label="Tenant Creation"
                  startIcon={<ChevronRightDouble />}
                  onClick={tenants}
                />
                <MenuItem
                  label="CV Rules Creation"
                  startIcon={<ChevronRightDouble />}
                  onClick={rules}
                />
                <MenuItem
                  label="CV Tenant and Rules Creation"
                  startIcon={<ChevronRightDouble />}
                  onClick={tenantsAndRules}
                />
                <MenuItem
                  label="User Creation"
                  startIcon={<ChevronRightDouble />}
                  onClick={users}
                />
                <MenuItem
                  label="File Download"
                  startIcon={<ChevronRightDouble />}
                  onClick={downloaduserresultsfile}
                />
              </MenuGroup>
            </Menu>
          </CardBody>
        </Card>
      </>
    )
  }
}
