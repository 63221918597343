import { Card, CardBody, Typography } from '@project44-manifest/react'
import { FileUpload } from 'primereact/fileupload'
import React, { useContext, useRef } from 'react'

import { Toast } from 'primereact/toast'

import { Context } from '../contexts/Context'

export const UploadFile = () => {
  const toast1 = useRef(null)
  const fileUploadRef = useRef(null)

  const { setFileUploaded, setFilename, filename } = useContext(Context)

  const onUpload = (e) => {
    console.log(e.xhr.responseText)
    setFilename(e.xhr.responseText)
    console.log(filename)
    localStorage.setItem('filename', filename)
    setFileUploaded(true)
  }

  return (
    <div>
      <Toast ref={toast1}></Toast>
      <Card style={{ marginBottom: '1rem' }}>
        <CardBody style={{ marginLeft: '.75rem', fontWeight: 'semi-bold' }}>
          <Typography>Upload the file.</Typography>
        </CardBody>
        <FileUpload
          ref={fileUploadRef}
          name="file"
          url={`${process.env.REACT_APP_API_URL_UPLOADFILES}`}
          onUpload={onUpload}
          mode="basic"
          style={{ marginLeft: '2rem' }}
        ></FileUpload>
      </Card>
    </div>
  )
}
