import 'primeicons/primeicons.css' //icons
import 'primereact/resources/primereact.min.css' //core css
import 'primereact/resources/themes/lara-light-indigo/theme.css' //theme
import React, { useEffect } from 'react'

import '/node_modules/primeflex/primeflex.css'

import { getUserInfo } from '../utils/LoginHelperEU'

import { ChooseTenant } from '../components/ChooseTenantEU'
import { DoRules } from '../components/DoRulesEU'
import { Layout } from '../components/EULayout'
import { Loading } from '../components/Loading'
import { ProcessRulesFile } from '../components/ProcessRulesFileEU'
import { RulesReset } from '../components/RulesResetEU'
import { UploadFile } from '../components/UploadFile'
import { Context } from '../contexts/Context'

function Rules() {
  const [userInfo, setUserInfo] = React.useState()
  const [tenantId, setTenantId] = React.useState([])
  const [tenantName, setTenantName] = React.useState('')
  const [processFile, setProcessFile] = React.useState('')
  const [tenantData, setTenantData] = React.useState([])
  const [selectedTenant, setSelectedTenant] = React.useState(null)
  const [tenantChosen, setTenantChosen] = React.useState(false)
  const [fileUploaded, setFileUploaded] = React.useState(false)
  const [processFinished, setProcessFinished] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [filename, setFilename] = React.useState('')
  const [tenantsCreated, setTenantsCreated] = React.useState(false)

  async function authUserInfo() {
    let res = await getUserInfo()
    console.log(res)
    return res
  }

  useEffect(() => {
    if (!userInfo) {
      let resp = authUserInfo()
      setUserInfo(resp)
    }
  }, [userInfo])

  useEffect(() => {
    if (selectedTenant) {
      setTenantId(selectedTenant.tenantId)
    }
  }, [selectedTenant])

  if (loading) {
    return (
      <>
        <Layout></Layout>
        <Loading></Loading>
      </>
    )
  } else if (fileUploaded === false && !loading) {
    return (
      <Context.Provider
        value={{
          userInfo,
          setUserInfo,
          tenantId,
          setTenantId,
          tenantName,
          setTenantName,
          processFile,
          setProcessFile,
          tenantData,
          setTenantData,
          selectedTenant,
          setSelectedTenant,
          tenantChosen,
          setTenantChosen,
          fileUploaded,
          setFileUploaded,
          processFinished,
          setProcessFinished,
          loading,
          setLoading,
          filename,
          setFilename,
          tenantsCreated,
          setTenantsCreated,
        }}
      >
        <div>
          <Layout></Layout>
        </div>
        {tenantChosen === false ? (
          <>
            <ChooseTenant></ChooseTenant>
          </>
        ) : (
          <>
            <UploadFile></UploadFile>
          </>
        )}
      </Context.Provider>
    )
  } else if (fileUploaded === true && processFinished === false && !loading) {
    return (
      <Context.Provider
        value={{
          userInfo,
          setUserInfo,
          tenantId,
          setTenantId,
          tenantName,
          setTenantName,
          processFile,
          setProcessFile,
          tenantData,
          setTenantData,
          selectedTenant,
          setSelectedTenant,
          tenantChosen,
          setTenantChosen,
          fileUploaded,
          setFileUploaded,
          processFinished,
          setProcessFinished,
          loading,
          setLoading,
          filename,
          setFilename,
          tenantsCreated,
          setTenantsCreated,
        }}
      >
        <div>
          <Layout></Layout>
        </div>
        {processFile === '' ? (
          <>
            <ProcessRulesFile></ProcessRulesFile>
          </>
        ) : (
          <>
            <DoRules></DoRules>
          </>
        )}
      </Context.Provider>
    )
  } else if (fileUploaded === true && processFinished === true && !loading) {
    return (
      <Context.Provider
        value={{
          userInfo,
          setUserInfo,
          tenantId,
          setTenantId,
          tenantName,
          setTenantName,
          processFile,
          setProcessFile,
          tenantData,
          setTenantData,
          selectedTenant,
          setSelectedTenant,
          tenantChosen,
          setTenantChosen,
          fileUploaded,
          setFileUploaded,
          processFinished,
          setProcessFinished,
          loading,
          setLoading,
          filename,
          setFilename,
          tenantsCreated,
          setTenantsCreated,
        }}
      >
        <>
          <div>
            <Layout></Layout>
          </div>
          <RulesReset></RulesReset>
        </>
      </Context.Provider>
    )
  }
}

export default Rules
