import 'primeicons/primeicons.css' //icons
import 'primereact/resources/primereact.min.css' //core css
import 'primereact/resources/themes/lara-light-indigo/theme.css' //theme
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import BadLogin from './pages/BadLogin'
import BeginPage from './pages/BeginPage'
import DownloadUserResultsFile from './pages/DownloadUserResultsFile'
import EUBeginPage from './pages/EUBeginPage'
import EURules from './pages/EURules'
import EUCreateTenants from './pages/EUTenants'
import EUTenantsAndRules from './pages/EUTenantsAndRules'
import EUUsers from './pages/EUUsers'
import Rules from './pages/Rules'
import SSOLogin from './pages/SSOLogin'
import Tenants from './pages/Tenants'
import TenantsAndRules from './pages/TenantsAndRules'
import Users from './pages/Users'

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<SSOLogin />}
        />
        <Route
          path="/beginpage"
          element={<BeginPage />}
        />
        <Route
          path="/createtenants"
          element={<Tenants />}
        />
        <Route
          path="/createrules"
          element={<Rules />}
        />
        <Route
          path="/tenantsandrules"
          element={<TenantsAndRules />}
        />
        <Route
          path="/users"
          element={<Users />}
        />
        <Route
          path="/eubeginpage"
          element={<EUBeginPage />}
        />
        <Route
          path="/eucreatetenants"
          element={<EUCreateTenants />}
        />
        <Route
          path="/eucreaterules"
          element={<EURules />}
        />
        <Route
          path="/eutenantsandrules"
          element={<EUTenantsAndRules />}
        />
        <Route
          path="/euusers"
          element={<EUUsers />}
        />
        <Route
          path="/downloaduserresultsfile"
          element={<DownloadUserResultsFile />}
        />
        <Route
          path="/badlogin"
          element={<BadLogin />}
        />
      </Routes>
    </Router>
  )
}

export default App
