import "primeicons/primeicons.css"; //icons
import "primereact/resources/primereact.min.css"; //core css
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import React, { useEffect } from "react";

import "/node_modules/primeflex/primeflex.css";

import { getUserInfo } from "../utils/LoginHelper";

import { Context } from "../contexts/Context";
import { Layout } from "../components/Layout";
import { Loading } from "../components/Loading";
import { ProcessFile } from "../components/ProcessFile";
import { TenantReset } from "../components/TenantReset";
import { UploadFile } from "../components/UploadFile";
import { UserRoles } from "../components/UserRoles";

function Tenants() {
  const [userInfo, setUserInfo] = React.useState();
  const [tenantId, setTenantId] = React.useState([]);
  const [tenantName, setTenantName] = React.useState("");
  const [processFile, setProcessFile] = React.useState("");
  const [tenantData, setTenantData] = React.useState([]);
  const [selectedTenant, setSelectedTenant] = React.useState(null);
  const [tenantChosen, setTenantChosen] = React.useState(false);
  const [fileUploaded, setFileUploaded] = React.useState(false);
  const [processFinished, setProcessFinished] = React.useState(false);
  const [tenantsCreated, setTenantsCreated] = React.useState(false);
  const [userRoles, setUserRoles] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [filename, setFilename] = React.useState("");

  async function authUserInfo() {
    let res = await getUserInfo();
    console.log(res);
    return res;
  }

  useEffect(() => {
    if (!userInfo) {
      let resp = authUserInfo();
      setUserInfo(resp);
    }
  }, [userInfo]);

  if (loading) {
    return (
      <>
        <Layout></Layout>
        <Loading></Loading>
      </>
    );
  } else if (fileUploaded === false && !loading) {
    return (
      <Context.Provider
        value={{
          userInfo,
          setUserInfo,
          tenantId,
          setTenantId,
          tenantName,
          setTenantName,
          processFile,
          setProcessFile,
          tenantData,
          setTenantData,
          selectedTenant,
          setSelectedTenant,
          tenantChosen,
          setTenantChosen,
          fileUploaded,
          setFileUploaded,
          processFinished,
          setProcessFinished,
          loading,
          setLoading,
          filename,
          setFilename,
          tenantsCreated,
          setTenantsCreated,
          userRoles,
          setUserRoles,
        }}
      >
        <>
          <div>
            <Layout></Layout>
          </div>
          <UploadFile></UploadFile>
        </>
      </Context.Provider>
    );
  } else if (fileUploaded === true && tenantsCreated === false && !loading) {
    return (
      <Context.Provider
        value={{
          userInfo,
          setUserInfo,
          tenantId,
          setTenantId,
          tenantName,
          setTenantName,
          processFile,
          setProcessFile,
          tenantData,
          setTenantData,
          selectedTenant,
          setSelectedTenant,
          tenantChosen,
          setTenantChosen,
          fileUploaded,
          setFileUploaded,
          processFinished,
          setProcessFinished,
          loading,
          setLoading,
          filename,
          setFilename,
          tenantsCreated,
          setTenantsCreated,
          userRoles,
          setUserRoles,
        }}
      >
        <>
          <div>
            <Layout></Layout>
          </div>
          <ProcessFile></ProcessFile>
        </>
      </Context.Provider>
    );
  } else if (fileUploaded === true && tenantsCreated === true && !loading) {
    return (
      <Context.Provider
        value={{
          userInfo,
          setUserInfo,
          tenantId,
          setTenantId,
          tenantName,
          setTenantName,
          processFile,
          setProcessFile,
          tenantData,
          setTenantData,
          selectedTenant,
          setSelectedTenant,
          tenantChosen,
          setTenantChosen,
          fileUploaded,
          setFileUploaded,
          processFinished,
          setProcessFinished,
          loading,
          setLoading,
          filename,
          setFilename,
          tenantsCreated,
          setTenantsCreated,
          userRoles,
          setUserRoles,
        }}
      >
        <>
          <div>
            <Layout></Layout>
          </div>
          {processFinished === false ? (
            <>
              <UserRoles></UserRoles>
            </>
          ) : (
            <>
              <TenantReset></TenantReset>
            </>
          )}
        </>
      </Context.Provider>
    );
  }
}

export default Tenants;
