export async function getUserInfo() {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
    credentials: "include",
  };

  let response = await fetch(
    process.env.REACT_APP_API_URL_USERINFO_EU,
    requestOptions
  );

  console.log(response.status);

  if (response.status === 401 || response.status === 403) {
    window.location.assign(process.env.REACT_APP_BADLOGIN_URL_EU);
  } else {
    let responseJson = await response.json();

    let firstName = responseJson.firstName;
    let lastName = responseJson.lastName;
    let tenantId = responseJson.tenantId;
    let companyName = responseJson.companyName;
    let userId = responseJson.id;
    let tenUuid = responseJson.tenantUuid;

    return { firstName, lastName, tenantId, companyName, userId, tenUuid };
  }
}

export async function Logout() {
  var requestOptions = {
    method: "POST",
    credentials: "include",
  };
  await fetch(
    "https://eu12.api.project44.com/api/portal/v2/logout",
    requestOptions
  );
  sessionStorage.removeItem("AuthToken_usprod");
  window.location.assign("https://eu12.voc.project44.com/portal/v2/login");
}
