import { Button, Card, CardBody, Typography } from '@project44-manifest/react'
import 'primeicons/primeicons.css' //icons
import 'primereact/resources/primereact.min.css' //core css
import 'primereact/resources/themes/lara-light-indigo/theme.css' //theme
import { Toast } from 'primereact/toast'
import React, { useEffect, useRef } from 'react'

import '/node_modules/primeflex/primeflex.css'

import { getUserInfo } from '../utils/LoginHelper'

import { Layout } from '../components/Layout'
import { Context } from '../contexts/Context'
import { deleteUserFile, downloadUsersFile } from '../utils/ApiHelper'

function DownloadUserResultsFile() {
  const [userInfo, setUserInfo] = React.useState()
  const [tenantId, setTenantId] = React.useState([])
  const [tenantName, setTenantName] = React.useState('')
  const [processFile, setProcessFile] = React.useState('')
  const [tenantData, setTenantData] = React.useState([])
  const [selectedTenant, setSelectedTenant] = React.useState(null)
  const [tenantChosen, setTenantChosen] = React.useState(false)
  const [fileUploaded, setFileUploaded] = React.useState(false)
  const [processFinished, setProcessFinished] = React.useState(false)
  const [tenantsCreated, setTenantsCreated] = React.useState(false)
  const [usersCreated, setUsersCreated] = React.useState(false)
  const [userRoles, setUserRoles] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [filename, setFilename] = React.useState('')

  const toast = useRef(null)

  const showDownloadToast = (event) => {
    toast.current.show({
      severity: 'info',
      summary: 'Download Success',
      detail: 'Users.json',
      life: 3000,
    })
  }

  const showDeleteToast = (event) => {
    toast.current.show({
      severity: 'warn',
      summary: 'Delete Success',
      detail: 'Users.json',
      life: 3000,
    })
  }

  async function authUserInfo() {
    let res = await getUserInfo()
    console.log(res)
    return res
  }

  async function handleDeleteUsersFileClick() {
    let res = await authUserInfo()
    console.log(res)
    await deleteUserFile(res.userId)
    showDeleteToast()
  }

  async function handleDownloadUsersFileClick() {
    let res = await authUserInfo()
    console.log(res)
    downloadUsersFile(res.userId)
    showDownloadToast()
  }

  useEffect(() => {
    if (!userInfo) {
      let resp = authUserInfo()
      setUserInfo(resp)
    }
  }, [userInfo])

  return (
    <Context.Provider
      value={{
        userInfo,
        setUserInfo,
        tenantId,
        setTenantId,
        tenantName,
        setTenantName,
        processFile,
        setProcessFile,
        tenantData,
        setTenantData,
        selectedTenant,
        setSelectedTenant,
        tenantChosen,
        setTenantChosen,
        fileUploaded,
        setFileUploaded,
        processFinished,
        setProcessFinished,
        loading,
        setLoading,
        filename,
        setFilename,
        tenantsCreated,
        setTenantsCreated,
        userRoles,
        setUserRoles,
        usersCreated,
        setUsersCreated,
      }}
    >
      <div>
        <Layout></Layout>
      </div>
      <div>
        <Toast ref={toast}></Toast>
        <Card style={{ marginBottom: '1rem' }}>
          <CardBody style={{ marginLeft: '.75rem', fontWeight: 'semi-bold' }}>
            <Typography>
              Download the Create Users results file. This file will be updated
              consistently while the User Creation File is being processed in
              the background. Each User created in a given file process will be
              assigned a numeric count value, which will be printed on the User
              Results File. Once you see the User count in the results file that
              matches the total number of Users created, you may delete the User
              Results File below.
            </Typography>
          </CardBody>
          <Button
            variant="primary"
            style={{ marginLeft: '2rem' }}
            onClick={handleDownloadUsersFileClick}
          >
            Download User Results File
          </Button>
          <Button
            variant="danger"
            style={{ marginLeft: '2rem' }}
            onClick={handleDeleteUsersFileClick}
          >
            Delete User Results File
          </Button>
        </Card>
      </div>
    </Context.Provider>
  )
}

export default DownloadUserResultsFile
