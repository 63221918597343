import "primeicons/primeicons.css"; //icons
import "primereact/resources/primereact.min.css"; //core css
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme

import { Card, CardBody, Typography } from "@project44-manifest/react";

import { Layout } from "../components/Layout";

function BadLogin() {
  return (
    <>
      <Layout></Layout>
      <Card style={{ marginBottom: "1rem" }}>
        <CardBody
          style={{ marginLeft: ".75rem", fontWeight: "semi-bold" }}
        >
          <Typography>You must be a Project44 Admin in order to use this application.</Typography>
        </CardBody>
      </Card>
    </>
  );
}

export default BadLogin;
