import React from "react";
import { Card, CardBody, Typography, Spinner } from "@project44-manifest/react";

export const Loading = () => {
  return (
    <>
      <div>
        <Card style={{ marginBottom: "1rem" }}>
          <CardBody style={{ marginLeft: "2rem", fontWeight: "semi-bold" }}>
            <Typography>
              Please do not close your browser, click the back button, or
              refresh this page. Depending on the number of Tenants, Rules, or
              Users being created, this process can take some time. The page
              will load the next steps once this process has finished.
            </Typography>
          </CardBody>
          <Spinner size="large" style={{ marginLeft: "2rem" }} />
        </Card>
      </div>
    </>
  );
};
