import React, { useEffect, useState } from "react";

import "primeicons/primeicons.css"; //icons
import "primereact/resources/primereact.min.css"; //core css
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme

import { Card, CardBody, Typography } from "@project44-manifest/react";

import { Layout } from "../components/Layout";

function SSOLogin() {
  const [redirectTo, setRedirectTo] = useState(false);
  const [devEnv, setDevEnv] = useState(false);

  const devUrl = `${process.env.REACT_APP_VOC_URL_NA}${process.env.REACT_APP_REDIRECT_URL_DEV}`;

  const naUrl = `${process.env.REACT_APP_VOC_URL_NA}${process.env.REACT_APP_REDIRECT_URL_NA}`;

  const euUrl = `${process.env.REACT_APP_VOC_URL_EU}${process.env.REACT_APP_REDIRECT_URL_EU}`;

  useEffect(() => {
    if (
      window.location.hostname ===
      "dev.visibility-na-admin.labs-na12.voc.project44.com"
    ) {
      setDevEnv(true);
      window.location.assign(devUrl);
    } else if (
      window.location.hostname === "visibility-na-admin.labs-na12.voc.project44.com") {
      setRedirectTo(false);
      window.location.assign(naUrl);
    } else if (
      window.location.hostname === "visibility-eu-admin.labs-na12.voc.project44.com") {
      setRedirectTo(true);
      window.location.assign(euUrl);
    }
  }, [euUrl, naUrl, devUrl, devEnv, redirectTo]);

  if (
    window.location.hostname ===
    "dev.visibility-na-admin.labs-na12.voc.project44.com"
  ) {
    return (
      <>
        <Layout></Layout>
        <Card style={{ marginBottom: "1rem" }}>
          <CardBody style={{ marginLeft: ".75rem", fontWeight: "semi-bold" }}>
            <Typography><p>Redirecting to NA VOC for Login.</p></Typography>
          </CardBody>
        </Card>
      </>
    );
  } else if (window.location.hostname === "visibility-na-admin.labs-na12.voc.project44.com") {
    return (
      <>
        <Layout></Layout>
        <Card style={{ marginBottom: "1rem" }}>
          <CardBody style={{ marginLeft: ".75rem", fontWeight: "semi-bold" }}>
            <Typography><p>Redirecting to NA VOC for Login.</p></Typography>
          </CardBody>
        </Card>
      </>
    );
  } else {
    return (
      <>
        <Layout></Layout>
        <Card style={{ marginBottom: "1rem" }}>
          <CardBody style={{ marginLeft: ".75rem", fontWeight: "semi-bold" }}>
            <Typography><p>Redirecting to EU VOC for Login.</p></Typography>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default SSOLogin;
