import Axios from 'axios'
import FileDownload from 'js-file-download'

export async function processFileUpload(fileName, userId) {
  var myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')

  var url = new URL(`${process.env.REACT_APP_API_URL_PROCESSFILE_EU}`)

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
    credentials: 'include',
    body: JSON.stringify({ fileName: fileName, userId: userId }),
  }

  let response = await fetch(url, requestOptions)

  let responseJson = await response.json()

  await downloadTenantsFile(userId)

  return responseJson
}

export async function processUsersFileUpload(fileName, userId) {
  var myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')

  var url = new URL(`${process.env.REACT_APP_API_URL_PROCESSUSERSFILE_EU}`)

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
    credentials: 'include',
    body: JSON.stringify({ fileName: fileName, userId: userId }),
  }

  let response = await fetch(url, requestOptions)

  let responseJson = await response.json()

  return responseJson
}

export async function processRulesFileUpload(fileName) {
  var myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')

  var url = new URL(`${process.env.REACT_APP_API_URL_PROCESSRULESFILE_EU}`)

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
    credentials: 'include',
    body: JSON.stringify({ fileName: fileName }),
  }

  let response = await fetch(url, requestOptions)

  let responseJson = await response.json()

  return responseJson
}

export async function processUserRoles(inputTenantList, userId) {
  var myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')

  var url = new URL(`${process.env.REACT_APP_API_URL_USERROLES_EU}`)

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
    credentials: 'include',
    body: JSON.stringify({
      userId: userId,
      inputTenantList: JSON.stringify(inputTenantList),
    }),
  }

  console.log(requestOptions)

  await fetch(url, requestOptions)

  await downloadTenantsFile(userId)
}

export function processCreateUsers(tenantId, inputUserList, userId, tenUuid) {
  var myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')

  let tenNumId = Number(tenantId)
  var url = new URL(`${process.env.REACT_APP_API_URL_CREATEUSERS_EU}`)

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
    credentials: 'include',
    body: JSON.stringify({
      id: tenNumId,
      userId: userId,
      tenUuid: tenUuid,
      inputTenantList: JSON.stringify(inputUserList),
    }),
  }

  console.log(requestOptions)

  fetch(url, requestOptions)
}

export async function doRules(tenantId, inputTenantList, userId, tenUuid) {
  var myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')

  let tenNumId = Number(tenantId)
  var url = new URL(`${process.env.REACT_APP_API_URL_DORULES_EU}`)

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
    credentials: 'include',
    body: JSON.stringify({
      id: tenNumId,
      userId: userId,
      tenUuid: tenUuid,
      inputTenantList: JSON.stringify(inputTenantList),
    }),
  }

  console.log(requestOptions)

  await fetch(url, requestOptions)

  await downloadRulesFile(userId)
}

export async function doWork(tenantId, inputTenantList, userId, tenUuid) {
  var myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')

  let tenNumId = Number(tenantId)
  var url = new URL(`${process.env.REACT_APP_API_URL_DOPROCESS_EU}`)

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
    credentials: 'include',
    body: JSON.stringify({
      id: tenNumId,
      userId: userId,
      tenUuid: tenUuid,
      inputTenantList: JSON.stringify(inputTenantList),
    }),
  }

  console.log(requestOptions)

  await fetch(url, requestOptions)

  await downloadRulesFile(userId)
}

async function downloadTenantsFile(userId) {
  Axios({
    url: `${process.env.REACT_APP_API_URL_DOWNLOADSUCCESSTENANTS}?userId=${userId}`,
    method: 'GET',
    responseType: 'blob',
  }).then((res) => {
    console.log(res)
    FileDownload(res.data, 'Tenants.json')
  })
}

async function downloadRulesFile(userId) {
  Axios({
    url: `${process.env.REACT_APP_API_URL_DOWNLOADSUCCESSRULES}?userId=${userId}`,
    method: 'GET',
    responseType: 'blob',
  }).then((res) => {
    console.log(res)
    FileDownload(res.data, 'Rules.json')
  })
}

export async function downloadUsersFile(userId) {
  Axios({
    url: `${process.env.REACT_APP_API_URL_DOWNLOADSUCCESSUSERS}?userId=${userId}`,
    method: 'GET',
    responseType: 'blob',
  }).then((res) => {
    console.log(res)
    FileDownload(res.data, 'Users.json')
  })
}

export async function deleteTenantFile(userId) {
  Axios({
    url: `${process.env.REACT_APP_API_URL_DELETETENANTSUCCESS}`,
    method: 'POST',
    data: { userId: userId },
  }).then((res) => {
    console.log(res + 'Deleted file.')
  })
}

export async function deleteUserFile(userId) {
  Axios({
    url: `${process.env.REACT_APP_API_URL_DELETEUSERSUCCESS}`,
    method: 'POST',
    data: { userId: userId },
  }).then((res) => {
    console.log(res + 'Deleted file.')
  })
}

export async function deleteRulesFile(userId) {
  Axios({
    url: `${process.env.REACT_APP_API_URL_DELETERULESSUCCESS}`,
    method: 'POST',
    data: { userId: userId },
  }).then((res) => {
    console.log(res + 'Deleted file.')
  })
}

export async function getTenantInfo(tenantName) {
  var myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')

  var encodedTenantName = encodeURIComponent(tenantName)

  var url = new URL(
    `${process.env.REACT_APP_API_URL_TENANTINFO_EU}?tenantName=${encodedTenantName}`,
  )

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
    credentials: 'include',
  }

  let response = await fetch(url, requestOptions)
  if (response === null) {
  } else {
    let responseJson = await response.json()
    console.log(responseJson)
    return responseJson
  }
}
