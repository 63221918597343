import { Button, Card, CardBody, Typography } from '@project44-manifest/react'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { deleteRulesFile } from '../utils/ApiHelper'
import { getUserInfo } from '../utils/LoginHelper'

import { Context } from '../contexts/Context'

export const RulesReset = () => {
  const {
    setLoading,
    setTenantId,
    setTenantName,
    setProcessFile,
    setTenantData,
    setTenantChosen,
    setSelectedTenant,
    setFilename,
    setFileUploaded,
    setProcessFinished,
  } = useContext(Context)

  const navigate = useNavigate()

  async function authUserInfo() {
    let res = await getUserInfo()
    console.log(res)
    return res
  }

  async function handleRulesResetClick() {
    let res = await authUserInfo()
    console.log(res)
    resetRulesScreen(res)
  }

  async function resetRulesScreen(res) {
    console.log('Resetting')
    console.log(res)
    await deleteRulesFile(res.userId)
    setTenantId([])
    setTenantName('')
    setProcessFile(false)
    setTenantData([])
    setSelectedTenant(null)
    setTenantChosen(false)
    setFileUploaded(false)
    setProcessFinished(false)
    setLoading(false)
    setFilename('')
    navigate('/beginpage')
  }

  return (
    <div>
      <Card style={{ marginBottom: '1rem' }}>
        <CardBody style={{ marginLeft: '.75rem', fontWeight: 'semi-bold' }}>
          <Typography>
            Click the Reset Button below to return to the main application page.
          </Typography>
        </CardBody>
        <Button
          variant="primary"
          style={{ marginLeft: '2rem' }}
          onClick={handleRulesResetClick}
        >
          Reset
        </Button>
      </Card>
    </div>
  )
}
