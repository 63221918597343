import { Button, Card, CardBody, Typography } from '@project44-manifest/react'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { deleteRulesFile, doRules } from '../utils/ApiHelperEU'

import { Context } from '../contexts/Context'
import { getUserInfo } from '../utils/LoginHelperEU'

export const DoRules = () => {
  const {
    setLoading,
    setProcessFinished,
    tenantId,
    setTenantId,
    setTenantName,
    processFile,
    setProcessFile,
    setTenantData,
    setTenantChosen,
    setSelectedTenant,
    setFilename,
    setFileUploaded,
    setTenantsCreated,
    setUserRoles,
  } = useContext(Context)

  const navigate = useNavigate()
  async function authUserInfo() {
    let res = await getUserInfo()
    console.log(res)
    return res
  }

  async function creatingSharedRules() {
    setLoading(true)
    await createRules()
    setProcessFinished(true)
    setLoading(false)
  }

  async function createRules() {
    let res = await authUserInfo()
    await doRules(tenantId, processFile, res.userId, res.tenUuid)
  }

  async function handleTenantsResetClick() {
    let res = await authUserInfo()
    console.log(res)
    resetTenantsScreen(res)
  }

  async function resetTenantsScreen(res) {
    console.log('Resetting')
    console.log(res)
    await deleteRulesFile(res.userId)
    setTenantId([])
    setTenantName('')
    setProcessFile(false)
    setTenantData([])
    setSelectedTenant(null)
    setTenantChosen(false)
    setFileUploaded(false)
    setProcessFinished(false)
    setLoading(false)
    setFilename('')
    setTenantsCreated(false)
    setUserRoles(false)
    navigate('/beginpage')
  }

  return (
    <div>
      <Card style={{ marginBottom: '1rem' }}>
        <CardBody style={{ marginLeft: '.75rem', fontWeight: 'semi-bold' }}>
          <Typography>
            Create the Collaborative Visibility Rules from the uploaded file for
            the requisite tenants.
          </Typography>
        </CardBody>
        <Button
          variant="primary"
          style={{ marginLeft: '2rem' }}
          onClick={creatingSharedRules}
        >
          Set Rules
        </Button>
      </Card>
      <Card style={{ marginBottom: '1rem' }}>
        <CardBody style={{ marginLeft: '.75rem', fontWeight: 'semi-bold' }}>
          <Typography>
            If no Collaborative Visibility Rules need to be created, click the
            Reset button below.
          </Typography>
        </CardBody>
        <Button
          variant="danger"
          style={{ marginLeft: '2rem' }}
          onClick={handleTenantsResetClick}
        >
          Reset
        </Button>
      </Card>
    </div>
  )
}
