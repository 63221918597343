import { Button, Card, CardBody, Typography } from '@project44-manifest/react'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { deleteTenantFile } from '../utils/ApiHelper'
import { getUserInfo } from '../utils/LoginHelper'

import { Context } from '../contexts/Context'

export const TenantReset = () => {
  const {
    setLoading,
    setTenantId,
    setTenantName,
    setProcessFile,
    setTenantData,
    setTenantChosen,
    setSelectedTenant,
    setFilename,
    setFileUploaded,
    setProcessFinished,
    setTenantsCreated,
    setUserRoles,
  } = useContext(Context)

  const navigate = useNavigate()
  async function authUserInfo() {
    let res = await getUserInfo()
    console.log(res)
    return res
  }

  async function handleTenantsResetClick() {
    let res = await authUserInfo()
    console.log(res)
    resetTenantsScreen(res)
  }

  async function resetTenantsScreen(res) {
    console.log('Resetting')
    console.log(res)
    await deleteTenantFile(res.userId)
    setTenantId([])
    setTenantName('')
    setProcessFile('')
    setTenantData([])
    setSelectedTenant(null)
    setTenantChosen(false)
    setFileUploaded(false)
    setProcessFinished(false)
    setLoading(false)
    setFilename('')
    setTenantsCreated(false)
    setUserRoles(false)
    navigate('/beginpage')
  }

  return (
    <div>
      <Card style={{ marginBottom: '1rem' }}>
        <CardBody style={{ marginLeft: '.75rem', fontWeight: 'semi-bold' }}>
          <Typography>
            Click the Reset Button below to return to the start screen.
          </Typography>
        </CardBody>
        <Button
          variant="primary"
          style={{ marginLeft: '2rem' }}
          onClick={handleTenantsResetClick}
        >
          Reset
        </Button>
      </Card>
    </div>
  )
}
