import { Button, Card, CardBody, Typography } from '@project44-manifest/react'
import React, { useContext } from 'react'

import { processUsersFileUpload } from '../utils/ApiHelper'

import { Context } from '../contexts/Context'
import { getUserInfo } from '../utils/LoginHelper'

export const ProcessUserFile = () => {
  const { setLoading, filename, setProcessFile } = useContext(Context)

  async function authUserInfo() {
    let res = await getUserInfo()
    console.log(res)
    return res
  }

  async function processingUsers() {
    console.log(filename)
    setLoading(true)
    let res = await authUserInfo()
    const response = await processUsersFileUpload(filename, res.userId)
    setProcessFile(response)
    setLoading(false)
  }

  return (
    <div>
      <Card style={{ marginBottom: '1rem' }}>
        <CardBody style={{ marginLeft: '.75rem', fontWeight: 'semi-bold' }}>
          <Typography>Create Users from the uploaded file</Typography>
        </CardBody>
        <Button
          variant="primary"
          style={{ marginLeft: '2rem' }}
          onClick={processingUsers}
        >
          Process User File
        </Button>
      </Card>
    </div>
  )
}
