import React, { useContext } from "react"
import { Button, Card, CardBody, Typography } from "@project44-manifest/react"

import { processFileUpload } from "../utils/ApiHelperEU";

import { getUserInfo } from "../utils/LoginHelperEU";
import { Context } from "../contexts/Context"


export const ProcessFile = () => {
  const { setLoading, filename, setTenantsCreated, setProcessFile } = useContext(Context);

  async function authUserInfo() {
    let res = await getUserInfo();
    console.log(res);
    return res;
  }

  async function processingTenants() {
    setLoading(true);
    let res = await authUserInfo();
    const response = await processFileUpload(filename, res.userId);
    setProcessFile(response);
    setTenantsCreated(true);
    setLoading(false);
  }

  return (
    <div>
      <Card style={{ marginBottom: "1rem" }}>
        <CardBody
          style={{ marginLeft: ".75rem", fontWeight: "semi-bold" }}
        >
          <Typography>
            Create Tenants from the uploaded file
          </Typography>
        </CardBody>
        <Button
          variant="primary"
          style={{ marginLeft: "2rem" }}
          onClick={processingTenants}
        >
          Create Tenants
        </Button>
      </Card>
    </div>
  )
}
