import { Button, Card, CardBody, Typography } from '@project44-manifest/react'
import React, { useContext } from 'react'

import { processRulesFileUpload } from '../utils/ApiHelperEU'

import { Context } from '../contexts/Context'
import { getUserInfo } from '../utils/LoginHelperEU'

export const ProcessRulesFile = () => {
  const { setLoading, filename, setProcessFile } = useContext(Context)

  async function authUserInfo() {
    let res = await getUserInfo()
    console.log(res)
    return res
  }

  async function processingFile() {
    setLoading(true)
    let res = await authUserInfo()
    console.log(res)
    const response = await processRulesFileUpload(filename)
    setProcessFile(response)
    setLoading(false)
  }

  return (
    <div>
      <Card style={{ marginBottom: '1rem' }}>
        <CardBody style={{ marginLeft: '.75rem', fontWeight: 'semi-bold' }}>
          <Typography>
            Verify Tenant and Tenant User permissions in order to create CV
            rules.
          </Typography>
        </CardBody>
        <Button
          variant="primary"
          style={{ marginLeft: '2rem' }}
          onClick={processingFile}
        >
          Process Rules File
        </Button>
      </Card>
    </div>
  )
}
