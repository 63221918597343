import 'primeicons/primeicons.css' //icons
import 'primereact/resources/primereact.min.css' //core css
import 'primereact/resources/themes/lara-light-indigo/theme.css' //theme
import React, { useEffect } from 'react'

import '/node_modules/primeflex/primeflex.css'

import { Card, CardBody, Typography } from '@project44-manifest/react'

import { getUserInfo } from '../utils/LoginHelper'

import { Layout } from '../components/Layout'
import { Context } from '../contexts/Context'

function BeginPage() {
  const [userInfo, setUserInfo] = React.useState()
  const [tenantId, setTenantId] = React.useState([])
  const [tenantName, setTenantName] = React.useState('')
  const [processFile, setProcessFile] = React.useState('')
  const [tenantData, setTenantData] = React.useState([])
  const [selectedTenant, setSelectedTenant] = React.useState(null)
  const [tenantChosen, setTenantChosen] = React.useState(false)
  const [fileUploaded, setFileUploaded] = React.useState(false)
  const [processFinished, setProcessFinished] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [filename, setFilename] = React.useState('')

  async function authUserInfo() {
    let res = await getUserInfo()
    console.log(res)
    return res
  }

  useEffect(() => {
    if (!userInfo) {
      let resp = authUserInfo()
      setUserInfo(resp)
    }
  }, [userInfo])

  useEffect(() => {
    if (selectedTenant) {
      setTenantId(selectedTenant.tenantId)
    }
  }, [selectedTenant])
  return (
    <>
      <Context.Provider
        value={{
          userInfo,
          setUserInfo,
          tenantId,
          setTenantId,
          tenantName,
          setTenantName,
          processFile,
          setProcessFile,
          tenantData,
          setTenantData,
          selectedTenant,
          setSelectedTenant,
          tenantChosen,
          setTenantChosen,
          fileUploaded,
          setFileUploaded,
          processFinished,
          setProcessFinished,
          loading,
          setLoading,
          filename,
          setFilename,
        }}
      >
        <Layout></Layout>
        <Card style={{ marginBottom: '1rem' }}>
          <CardBody style={{ marginLeft: '.75rem', fontWeight: 'semi-bold' }}>
            <Typography>
              <h1>Application Basic Usage</h1>
              <p>
                The Collaborative Visibily Admin Tools application has three
                separate use cases. Each of the following options can be found
                in the Menu options above.
              </p>
              <p>
                1. Tenant Creation - Create tenants based on an uploaded file.
              </p>
              <p>
                2. CV Rules Creation - Create CV Sharing rules based on an
                uploaded file for existing tenants.
              </p>
              <p>
                3. CV Tenant and Rules Creation - Create tenants, and then
                create CV sharing rules based on single uploaded file.
              </p>
              <p>
                4. User Creation/Update - Create users based on an uploaded file
                with associated Role IDs. Alternatively, update Role IDs for an
                existing user.
              </p>
              <p>
                5. User Results File Download - Download the Users.json file
                with the results from creating or updating users.
              </p>
            </Typography>
          </CardBody>
        </Card>
        <Card style={{ marginBottom: '1rem' }}>
          <CardBody style={{ marginLeft: '.75rem', fontWeight: 'semi-bold' }}>
            <Typography>
              <p>
                For more detailed usage instructions, please visit the Lab
                Services Confluence page&nbsp;
                <a
                  href="https://project44.atlassian.net/wiki/spaces/LA/pages/35611050086/Collaborative+Visibility+Admin+Tool+Soft+Live+Application"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here.
                </a>
              </p>
            </Typography>
          </CardBody>
        </Card>
      </Context.Provider>
    </>
  )
}

export default BeginPage
