import { Button, Card, CardBody, Typography } from '@project44-manifest/react'
import React, { useContext, useRef } from 'react'

import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'

import { Context } from '../contexts/Context'
import { getTenantInfo } from '../utils/ApiHelper'

export const ChooseTenant = () => {
  const toast2 = useRef(null)

  const {
    tenantName,
    setTenantName,
    tenantData,
    setTenantData,
    selectedTenant,
    setSelectedTenant,
    setTenantChosen,
  } = useContext(Context)

  const setTenantDataTable = () => {
    getTenantInfo(tenantName).then((data) => setTenantData(data))
  }

  const onRowSelect = (event) => {
    toast2.current.show({
      severity: 'info',
      summary: 'Tenant Selected',
      detail: `Tenant Name: ${event.data.name}`,
      life: 3000,
    })
  }

  const onRowUnselect = (event) => {
    toast2.current.show({
      severity: 'warn',
      summary: 'Tenant Unselected',
      detail: `Tenant Name: ${event.data.name}`,
      life: 3000,
    })
  }
  return (
    <div>
      <Toast ref={toast2}></Toast>
      <Card style={{ marginBottom: '1rem' }}>
        <CardBody style={{ marginLeft: '.75rem', fontWeight: 'semi-bold' }}>
          <Typography>Choose the Tenant.</Typography>
        </CardBody>
      </Card>
      <Card style={{ marginBottom: '1rem' }}>
        <span
          className="p-input-icon-left"
          style={{ marginLeft: '2rem' }}
        >
          <i className="pi pi-search" />
          <InputText
            value={tenantName}
            onChange={(e) => setTenantName(e.target.value)}
          />
        </span>
        <Button
          variant="primary"
          onClick={setTenantDataTable}
          style={{ marginLeft: '.5rem' }}
        >
          Search
        </Button>
      </Card>
      <Card style={{ marginBottom: '1rem' }}>
        <DataTable
          value={tenantData}
          selectionMode="single"
          selection={selectedTenant}
          onSelectionChange={(e) => setSelectedTenant(e.value)}
          dataKey="name"
          responsiveLayout="scroll"
          onRowSelect={onRowSelect}
          onRowUnselect={onRowUnselect}
          style={{ marginLeft: '2rem' }}
        >
          <Column
            field="name"
            header="Tenant Name"
          ></Column>
          <Column
            field="tenantId"
            header="Tenant ID"
          ></Column>
          <Column
            field="tenantUuid"
            header="Tenant UUID"
          ></Column>
        </DataTable>
      </Card>
      <Card style={{ marginBottom: '1rem' }}>
        <CardBody style={{ marginLeft: '.75rem', fontWeight: 'semi-bold' }}>
          <Typography>Confirm Tenant Selection</Typography>
        </CardBody>
        <Button
          variant="primary"
          style={{ marginLeft: '2rem' }}
          onClick={() => setTenantChosen(true)}
        >
          Confirm
        </Button>
      </Card>
    </div>
  )
}
